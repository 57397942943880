
import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";
import { Role } from "@/types/api/admin";
import { allRole, adminRoleById } from "@/api/admin";
import copy from "@/utils/deepCopy";
import print from "@/utils/print";
import { Form } from "element-ui/types";

@Component
export default class Header extends Vue {
  @Ref("form") formRef: Form;
  @Prop() private form!: any;
  @Prop([String]) private dialogTitle!: string;
  @Prop([Boolean]) private dialogVisible!: boolean;
  // 初始数据可以直接声明为实例的 property
  roleList: Array<Role> = [];
  formData: any = {};

  rules = {
    username: [{ required: true, message:"请输入账号" }],
    nickName: [{ required: true, message:"请输入姓名" }],
    password: [{ required: true, message:"请输入密码" }],
    phone: [{ required: true, message:"请输入手机号" }],
  }

  mounted() {
    this.getAllRole();
  }

  confirm() {
    this.formRef?.validate().then(() => {
      this.$emit('confirm', this.formData);
    }).catch((err) => {
      console.log(err)
    })
  }

  @Watch("form", { deep: true })
  setForm(val: any) {
    this.formData = {
      ...val,
      ids: [],
    };
    adminRoleById({ adminId: this.form.id }).then((res) => {
      this.formData.ids = (res.data && res.data.map((e: any) => e.id)) || [];
    });
  }

  // 获取所有角色
  getAllRole() {
    allRole().then((res: any) => {
      this.roleList = res.data;
    });
  }
  // 关闭之前
  beforeClose() {
    this.formRef?.resetFields();
    this.$emit("close");
  }
}
