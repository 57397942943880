/**
 * 防抖
 * @param {callback} callback - 回调函数
 * @param {number} [wait] - 时间(默认500毫秒) 
 */
let timeout: any;
const debounce = (callback: Function, wait: number = 500): void => {
  if (timeout !== 0) clearTimeout(timeout)
  timeout = setTimeout(() => {
    timeout = 0
    callback && callback()
  }, wait);
}

export default debounce;