import { service } from '@/ajax/request'
import { AllRoleList } from '@/types/api/admin'
import { User } from '@/types/api/user'
import { Id } from '@/types/common'

// 根据用户名或姓名分页获取用户列表
export const adminList = (params: AllRoleList) => (
  service({
    url: '/admin/list',
    method: 'POST',
    params,
  })
)

// 用户添加 或 注册
export const adminRegister = (params: User) => (
  service({
    url: '/admin/register',
    method: 'POST',
    params
  })
)

interface AdminRoleUpdate {
  adminId: number,
  roleIds: string
}

// 给用户分配角色
export const adminRoleUpdate = (params: AdminRoleUpdate) => (
  service({
    url: '/admin/role/update',
    method: 'POST',
    params,
    type: 'form'
  })
)

// 修改指定用户信息
export const adminUpdate = (params: User) => (
  service({
    url: `/admin/update/${params.id}`,
    method: 'POST',
    params
  })
)

// 删除指定用户信息
export const adminDelete = (params: Id) => (
  service({
    url: `/admin/delete/${params.id}`,
    method: 'POST',
    params,
    type: 'form'
  })
)